import Cookies from "universal-cookie"

export const cookies = new Cookies()

export const Cookie = {
  NECESSARY: "n_nec",
  STATISTICS: "n_sta",
  GOOGLE_ANALYTICS: "_ga",
  GOOGLE_ANALYTICS_GAT: "_gat",
  GOOGLE_ANALYTICS_GID: "_gid",
}

export function getCookie(key) {
  return cookies.get(key)
}

const twoYearsInSeconds = 2 * 365 * 24 * 60 * 60
const cookiesSetOption = {
  path: "/",
  maxAge: twoYearsInSeconds,
}

export function setCookie(key, value) {
  cookies.set(key, value, cookiesSetOption)
}
