import ReactGA from "react-ga"

import "normalize.css"
import "./src/global.css"
import { init } from "./src/google-analytics"

export function onClientEntry() {
  init()
}

export function onRouteUpdate({ location }) {
  const pathname = location.pathname
  console.info(`Google Analytics pageview: ${pathname}`)
  ReactGA.pageview(pathname)
}
