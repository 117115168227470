import ReactGA from "react-ga"
import { Cookie, cookies, getCookie } from "./cookies"
import data from "./data.json"

const trackingId = data.googleAnalytics.trackingId

cookies.addChangeListener(({ name, value }) => {
  if (name === Cookie.STATISTICS) {
    toggle(value === "true")
  }
})

function toggle(isStatisticsEnabled) {
  if (!window) {
    return
  }

  window[`ga-disable-${trackingId}`] = !isStatisticsEnabled

  const newStatus = isStatisticsEnabled ? "enabled" : "disabled"
  console.info(`Google Analytics is now ${newStatus}.`)
}

export function init() {
  ReactGA.initialize(trackingId)
  ReactGA.set({ anonymizeIp: true })
  console.info("Google Analytics is initialized.")

  toggle(getCookie(Cookie.STATISTICS) === "true")
}
